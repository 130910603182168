/* line 16, scss/60-components/_nearby-location.scss */
.c-target-locations-icon {
	top: 50%;
	transform: translate(-0, -50%);
	z-index: 1;
	position: absolute;
	cursor: pointer;
	right: 10px;
	height: auto;
}

/* line 25, scss/60-components/_nearby-location.scss */
.icons-number-one .c-target-locations-icon {
	right: 0px;
	padding: 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icons-number-one .c-target-locations-icon {
	right: auto;
	left: 0px;
}

/* line 25, scss/60-components/_nearby-location.scss */
.icons-number-two .c-target-locations-icon {
	right: 0px;
	padding: 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icons-number-two .c-target-locations-icon {
	right: auto;
	left: 0px;
}

/* line 25, scss/60-components/_nearby-location.scss */
.icons-number-three .c-target-locations-icon {
	right: 30px;
	padding: 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icons-number-three .c-target-locations-icon {
	right: auto;
	left: 30px;
}

@keyframes sideFromRight {
	from {
		max-width: 0;
	}
	to {
		max-width: 375px;
	}
}

/* line 23, scss/70-modules/quicksearch/_module.scss */
.homepage .global-header.global-header--mobile-first:not(.header-with-quicksearch) .quicksearch__wrapper--large,
.homepage .paw-header.paw-header--mobile-first:not(.header-with-quicksearch) .quicksearch__wrapper--large {
	display: none;
}

/* line 31, scss/70-modules/quicksearch/_module.scss */
.global-header.global-header--mobile-first .m-quicksearch__mobile-first--large .quicksearch__wrapper,
.paw-header.paw-header--mobile-first .m-quicksearch__mobile-first--large .quicksearch__wrapper {
	max-width: 320px;
}

/* line 36, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch {
	width: 100%;
	height: auto;
}

/* line 40, scss/70-modules/quicksearch/_module.scss */
.homepage .global-header.header-without-quicksearch .m-quicksearch {
	display: none;
}

/* line 45, scss/70-modules/quicksearch/_module.scss */
.homepage .global-header:not(.header-with-quicksearch) .m-quicksearch {
	display: none;
}

/* line 51, scss/70-modules/quicksearch/_module.scss */
.homepage .paw-header:not(.header-with-quicksearch) .m-quicksearch {
	display: none;
}

/* line 57, scss/70-modules/quicksearch/_module.scss */
.homepage .global-header.global-header--mobile-first:not(.header-with-quicksearch) .m-quicksearch {
	display: inline-block;
}

/* line 63, scss/70-modules/quicksearch/_module.scss */
.homepage .paw-header.paw-header--mobile-first:not(.header-with-quicksearch) .m-quicksearch {
	display: inline-block;
}

@media only screen and (max-width: 59.999em) {
	/* line 68, scss/70-modules/quicksearch/_module.scss */
	.m-quicksearch.is-bellow-header {
		position: absolute;
		bottom: -10px;
		transform: translate(0, 100%);
	}
}

/* line 76, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch input::-webkit-input-placeholder {
	color: rgb(var(--color-global-helper));
	font-style: normal;
	font-size: 1.083em;
	text-transform: none;
	opacity: 1;
}

/* line 84, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch input::-moz-placeholder {
	color: rgb(var(--color-global-helper));
	font-style: normal;
	font-size: 1.083em;
	text-transform: none;
	opacity: 1;
}

/* line 92, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch input:-ms-input-placeholder {
	color: rgb(var(--color-global-helper));
	font-style: normal;
	font-size: 1.083em;
	text-transform: none;
	opacity: 1;
}

/* line 101, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch input:-webkit-input-placeholder {
	font-style: normal;
}

/* line 104, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch input:-moz-input-placeholder {
	font-style: normal;
}

/* line 107, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch input:-ms-input-placeholder {
	font-style: normal;
}

/* line 113, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--flyout .c-search-suggest.is-open {
	max-width: 100%;
}

/* line 119, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--flyout-icon .quicksearch__wrapper {
	padding: 0;
	background-color: transparent;
}

/* line 124, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--flyout-icon .m-quicksearch--flyout.m-quicksearch--above-all {
	left: 0;
}

/* line 129, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--flyout-icon .m-quicksearch:not(.m-quicksearch--above-all) .quicksearch__icon {
	position: relative;
	top: 5px;
	transform: translateX(-50%);
	color: rgb(var(--color-dynamic-header-text));
	font-size: 1.3em;
}

@media only screen and (max-width: 29.999em) {
	/* line 131, scss/70-modules/quicksearch/_module.scss */
	.global-header.global-header.global-header--mobile-first .m-quicksearch--flyout-icon .m-quicksearch:not(.m-quicksearch--above-all) .quicksearch__icon {
		left: 50%;
	}
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 131, scss/70-modules/quicksearch/_module.scss */
	.global-header.global-header.global-header--mobile-first .m-quicksearch--flyout-icon .m-quicksearch:not(.m-quicksearch--above-all) .quicksearch__icon {
		left: 25px;
	}
}

/* line 148, scss/70-modules/quicksearch/_module.scss */
.paw-header--mobile-first .m-quicksearch--flyout-icon .m-quicksearch:not(.m-quicksearch--above-all) .quicksearch__icon {
	position: relative;
	top: 0;
	transform: none;
}

/* line 155, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--flyout-icon .m-quicksearch:not(.m-quicksearch--above-all) .search-suggest__wrapper {
	cursor: pointer;
}

/* line 159, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--flyout-icon .m-quicksearch:not(.m-quicksearch--above-all) .quicksearch__item {
	display: none;
}

@media only screen and (min-width: 60em) {
	/* line 165, scss/70-modules/quicksearch/_module.scss */
	.m-quicksearch--flyout-icon .search-suggest__close-text {
		display: none;
	}
}

/* line 171, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all {
	z-index: 9999999;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgb(var(--color-global-body));
	overflow: hidden;
}

@media only screen and (min-width: 48em) {
	/* line 188, scss/70-modules/quicksearch/_module.scss */
	.m-quicksearch--above-all.m-quicksearch--flyout {
		max-width: 375px;
		left: auto;
	}
}

/* line 199, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .c-search-suggest.is-open {
	flex-grow: 1;
	position: relative;
	border: 0;
}

/* line 204, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .c-search-suggest.is-open + .quicksearch__tooltip-wrapper {
	display: none;
	flex: 0;
}

/* line 211, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all:not(.m-quicksearch--cityhomes) .c-search-suggest.is-open {
	max-height: none;
}

/* line 217, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all.m-quicksearch--cityhomes .quicksearch__items {
	display: flex;
	flex-direction: row;
}

/* line 223, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .search-suggest__spinner-container,
.m-quicksearch--above-all .quicksearch__tooltip {
	position: absolute;
	top: 50%;
	transform: translate(-0, -50%);
}

/* line 230, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .search-suggest__spinner-container {
	width: 100%;
	height: auto;
}

/* line 235, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__tooltip {
	padding: 20px;
	box-sizing: border-box;
	max-height: 100%;
	width: 100%;
}

/* line 242, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__tooltip-title {
	padding-bottom: 10px;
	color: rgb(var(--color-dynamic-header-text));
}

/* line 256, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__item,
.m-quicksearch--above-all .search-suggest__channel-select {
	border: 0;
	background: none;
}

/* line 262, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .search-suggest__channel-select.selectboxit-enabled {
	padding-right: 20px;
	box-sizing: border-box;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-quicksearch--above-all .search-suggest__channel-select.selectboxit-enabled {
	padding-right: initial;
	padding-left: 20px;
}

/* line 267, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__tooltip-wrapper {
	display: block;
}

/* line 271, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__wrapper {
	flex: none;
	z-index: 3;
	border-bottom: 1px solid rgb(var(--color-global-border));
	background: none;
	padding: 0;
}

/* line 283, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__items {
	display: flex;
	align-items: center;
}

/* line 288, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .search-suggest__item,
.m-quicksearch--above-all .quicksearch__form-container {
	display: inherit;
}

/* line 293, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__form-container {
	flex-grow: 1;
	padding-left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-quicksearch--above-all .quicksearch__form-container {
	padding-left: initial;
	padding-right: 0;
}

/* line 298, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__form {
	width: 100%;
}

/* line 302, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .search-suggest__item {
	border-left: 1px solid rgb(var(--color-global-border));
	padding: 0 5px;
	text-align: center;
	max-width: 30%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-quicksearch--above-all .search-suggest__item {
	border-left: 0;
	border-right: 1px solid rgb(var(--color-global-border));
}

/* line 312, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .search-suggest__button,
.m-quicksearch--above-all .quicksearch__advanced-link {
	display: none;
}

/* line 317, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .c-search-suggest {
	z-index: 2;
}

/* line 320, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .c-search-suggest .group-data {
	background: none;
}

/* line 325, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .suggest-container__suggest-panel {
	padding: 0;
}

/* line 329, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .search-suggest__more-link {
	float: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-quicksearch--above-all .search-suggest__more-link {
	float: right;
}

/* line 333, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .list-inline__item--spaced {
	margin: 0;
}

/* line 339, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all input::-webkit-input-placeholder {
	color: rgb(var(--color-global-secondary));
}

/* line 343, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all input::-moz-placeholder {
	color: rgb(var(--color-global-secondary));
}

/* line 347, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all input:-ms-input-placeholder {
	color: rgb(var(--color-global-secondary));
}

/* line 355, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--focusin .quicksearch__tooltip-wrapper,
.m-quicksearch--focusin .c-search-suggest.is-open {
	max-height: 45vh;
}

@media only screen and (max-width: 29.999em) {
	/* line 360, scss/70-modules/quicksearch/_module.scss */
	.m-quicksearch--focusin .c-search-suggest.category.suggest-columns-2,
	.m-quicksearch--focusin .c-search-suggest.category.suggest-columns-3 {
		flex: 1;
	}
}

/* line 369, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch .is-editable .c-target-locations-icon {
	display: block;
}

/* line 391, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch .is-editable .target-locations-parent {
	padding-right: 30px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-quicksearch .is-editable .target-locations-parent {
	padding-right: initial;
	padding-left: 30px;
}

/* line 408, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch .c-target-locations-icon {
	display: none;
}

/* line 412, scss/70-modules/quicksearch/_module.scss */
.category-no-data {
	top: 50%;
	transform: translate(-0, -50%);
	left: 0;
	padding: 40px 20px;
	box-sizing: border-box;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .category-no-data {
	left: auto;
	right: 0;
}

/* line 418, scss/70-modules/quicksearch/_module.scss */
.category-no-data .search-suggest__group--active {
	margin: 10px 0 0;
	padding: 0;
}

/* line 422, scss/70-modules/quicksearch/_module.scss */
.category-no-data .search-suggest__group--active .suggest-container__links {
	background: none;
}

/* line 427, scss/70-modules/quicksearch/_module.scss */
.category-no-data .suggest-container__links {
	box-sizing: border-box;
	width: 100%;
}

/* line 435, scss/70-modules/quicksearch/_module.scss */
.search-suggest__item .selectboxit-container {
	text-align: left;
	padding: 5px 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .search-suggest__item .selectboxit-container {
	text-align: right;
}

/* line 440, scss/70-modules/quicksearch/_module.scss */
.search-suggest__item .selectboxit-arrow-container {
	right: -5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .search-suggest__item .selectboxit-arrow-container {
	right: auto;
	left: -5px;
}

/* line 444, scss/70-modules/quicksearch/_module.scss */
.search-suggest__item .selectboxit-text {
	text-indent: 5px;
}

/* line 449, scss/70-modules/quicksearch/_module.scss */
.search-suggest__channel-wrapper {
	flex-grow: 1;
}

/* line 453, scss/70-modules/quicksearch/_module.scss */
.search-suggest__close-button {
	padding: 10px 5px;
	cursor: pointer;
	background: none;
	border: 0;
}

/* line 468, scss/70-modules/quicksearch/_module.scss */
.search-suggest__no-data-text {
	text-align: center;
	padding: 5px 20px;
}

/* line 473, scss/70-modules/quicksearch/_module.scss */
.search-suggest__group--active {
	padding: 10px 0;
}

/* line 476, scss/70-modules/quicksearch/_module.scss */
.search-suggest__group--active .search-suggest__group {
	display: none;
}

/* line 481, scss/70-modules/quicksearch/_module.scss */
.search-suggest__item,
.search-suggest__group-header-link {
	display: none;
}

/* line 486, scss/70-modules/quicksearch/_module.scss */
.quicksearch__tooltip-wrapper {
	flex: 1 1 0;
	display: none;
	position: relative;
	overflow: auto;
}

/* line 492, scss/70-modules/quicksearch/_module.scss */
.is-closed + .quicksearch__tooltip-wrapper {
	height: 100%;
	flex: 1 1 0;
}

/* line 498, scss/70-modules/quicksearch/_module.scss */
.quicksearch__wrapper {
	border-radius: 0px;
	position: relative;
	display: inline-block;
	width: 100%;
	background-color: rgb(var(--color-global-primary));
	box-sizing: border-box;
}

/* line 513, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--inline .quicksearch__wrapper {
	background-color: transparent;
	padding-bottom: 0;
}

/* line 520, scss/70-modules/quicksearch/_module.scss */
.quicksearch__wrapper--section {
	z-index: 3;
	border-radius: 0;
	position: relative;
	display: none;
	box-shadow: inset 0 -5px 20px -10px rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 59.999em) {
	/* line 520, scss/70-modules/quicksearch/_module.scss */
	.quicksearch__wrapper--section {
		display: block;
		margin-bottom: 0;
		/* when in section, no margins */
	}
}

/* line 533, scss/70-modules/quicksearch/_module.scss */
.quicksearch__item-container {
	position: relative;
}

/* line 537, scss/70-modules/quicksearch/_module.scss */
.quicksearch__item {
	box-sizing: border-box;
	font-family: var(--font-family-text);
	background-color: rgb(var(--color-global-positive));
	color: rgb(var(--color-global-secondary));
	padding: 5px;
	width: 100%;
	border-radius: 0px;
	font-display: swap;
	line-height: 1.5;
	border: 1px solid rgb(var(--color-global-border));
	padding-left: 30px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .quicksearch__item {
	padding-left: initial;
	padding-right: 30px;
}

/* line 575, scss/70-modules/quicksearch/_module.scss */
.quicksearch__icon {
	top: 50%;
	transform: translate(-0, -50%);
	position: absolute;
	left: 10px;
	font-size: 1.167em;
	pointer-events: unset;
	color: #92a2c1;
	cursor: pointer;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .quicksearch__icon {
	left: auto;
	right: 10px;
}

/* line 601, scss/70-modules/quicksearch/_module.scss */
.m-quicksearch--above-all .quicksearch__icon {
	color: rgb(var(--color-global-secondary));
}

/* line 607, scss/70-modules/quicksearch/_module.scss */
.quicksearch__button {
	width: 100%;
}

/* line 611, scss/70-modules/quicksearch/_module.scss */
.quicksearch__advanced-link {
	display: inline-block;
}

/* line 620, scss/70-modules/quicksearch/_module.scss */
.quicksearch__tooltip-title.o-title {
	color: rgb(var(--color-global-positive));
	margin-bottom: 10px;
}

/* line 625, scss/70-modules/quicksearch/_module.scss */
.quicksearch__tooltip--title {
	color: rgb(var(--color-global-secondary));
}

/* line 629, scss/70-modules/quicksearch/_module.scss */
.quicksearch__tooltip-item {
	line-height: 1.4em;
	white-space: nowrap;
}

@media only screen and (min-width: 80em) {
	/* line 639, scss/70-modules/quicksearch/_module.scss */
	.quicksearch__search-type {
		min-width: 200px;
	}
}

/* line 644, scss/70-modules/quicksearch/_module.scss */
.can-shrink .quicksearch__search-type {
	min-width: 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 649, scss/70-modules/quicksearch/_module.scss */
	.quicksearch__term-input {
		max-width: 140px;
	}
}

@media only screen and (min-width: 48em) {
	/* line 649, scss/70-modules/quicksearch/_module.scss */
	.quicksearch__term-input {
		min-width: 200px;
	}
}

/* line 658, scss/70-modules/quicksearch/_module.scss */
.can-shrink .quicksearch__term-input {
	min-width: 0;
}

/* line 663, scss/70-modules/quicksearch/_module.scss */
.quicksearch__form {
	position: relative;
}

/* line 666, scss/70-modules/quicksearch/_module.scss */
.quicksearch__form--inline {
	display: flex;
	align-items: center;
}

/* line 671, scss/70-modules/quicksearch/_module.scss */
.quicksearch__form-icon, .quicksearch__form-text {
	flex: 1 1 auto;
	text-align: left;
	padding-right: 20px;
	box-sizing: border-box;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .quicksearch__form-icon, .is-rtl .quicksearch__form-text {
	text-align: right;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .quicksearch__form-icon, .is-rtl .quicksearch__form-text {
	padding-right: initial;
	padding-left: 20px;
}

/* line 679, scss/70-modules/quicksearch/_module.scss */
.quicksearch__form-button {
	flex: 0 1 auto;
	max-width: max-content;
}

/* line 684, scss/70-modules/quicksearch/_module.scss */
.quicksearch__form .quicksearch__form-with-button {
	display: flex;
}

/* line 690, scss/70-modules/quicksearch/_module.scss */
.quicksearch__form-with-button .search-suggest__wrapper {
	flex-grow: 1;
	margin-right: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .quicksearch__form-with-button .search-suggest__wrapper {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .quicksearch__form-with-button .search-suggest__wrapper {
		margin-right: 0;
	}
}

/* line 695, scss/70-modules/quicksearch/_module.scss */
.quicksearch__form-with-button .o-button {
	width: auto;
}

/* line 700, scss/70-modules/quicksearch/_module.scss */
.quicksearch__flyout {
	/* z-index must be larger than menu index (99) */
	z-index: 800;
	background: rgb(var(--color-global-positive));
	box-shadow: 0 8px 9px -5px rgba(100, 100, 100, 0.6);
}

/* line 704, scss/70-modules/quicksearch/_module.scss */
.quicksearch__flyout .grid--no-outer-margins {
	margin-top: 0;
}

/* line 708, scss/70-modules/quicksearch/_module.scss */
.quicksearch__flyout .slider__wrapper {
	margin-left: -7px;
	/* Needed to override margin from range slider. */
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .quicksearch__flyout .slider__wrapper {
	margin-left: initial;
	margin-right: -7px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .quicksearch__flyout .slider__wrapper {
		margin-left: 0;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 700, scss/70-modules/quicksearch/_module.scss */
	.quicksearch__flyout {
		position: fixed;
		left: 10px;
		right: 10px;
		box-sizing: border-box;
	}
}

@media only screen and (min-width: 48em) {
	/* line 700, scss/70-modules/quicksearch/_module.scss */
	.quicksearch__flyout {
		right: 0;
		position: absolute;
		top: 100%;
		width: 400px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .quicksearch__flyout {
		right: auto;
		left: 0;
	}
}

@media only screen and (min-width: 30em) {
	/* line 727, scss/70-modules/quicksearch/_module.scss */
	.quicksearch__flyout .quicksearch__price-label {
		display: block;
	}
}

/* line 736, scss/70-modules/quicksearch/_module.scss */
.quicksearch__flyout-inner {
	margin: 10px;
	padding: 10px;
	border: 1px solid rgb(var(--color-global-border));
}

/* line 742, scss/70-modules/quicksearch/_module.scss */
.quicksearch__flyout .quicksearch__advanced-search-link {
	width: 100%;
}

/* line 746, scss/70-modules/quicksearch/_module.scss */
.quicksearch__flyout .quicksearch__flyout-button--grow {
	flex-grow: 1;
}

/* line 751, scss/70-modules/quicksearch/_module.scss */
.quicksearch__flyout-button:first-child .o-button {
	margin-left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .quicksearch__flyout-button:first-child .o-button {
	margin-left: initial;
	margin-right: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .quicksearch__flyout-button:first-child .o-button {
		margin-left: 0;
	}
}

/* line 755, scss/70-modules/quicksearch/_module.scss */
.quicksearch__buttons {
	align-items: center;
	justify-content: center;
}

/* line 759, scss/70-modules/quicksearch/_module.scss */
.quicksearch__buttons .o-button {
	margin-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .quicksearch__buttons .o-button {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .quicksearch__buttons .o-button {
		margin-left: 0;
	}
}

/* line 763, scss/70-modules/quicksearch/_module.scss */
.quicksearch__channel {
	font-size: 0;
	overflow: hidden;
}

/* line 768, scss/70-modules/quicksearch/_module.scss */
.quicksearch__channel-button {
	font-size: 12px;
	font-size: 1rem;
	display: inline-block;
	padding: 10px 40px;
	box-sizing: border-box;
	border: 0;
	color: rgb(var(--color-global-positive));
	background: rgb(var(--color-global-secondary));
}

/* line 781, scss/70-modules/quicksearch/_module.scss */
body:not(.is-touch-device) .quicksearch__channel-button:hover {
	color: rgb(var(--color-global-positive));
}

/* line 785, scss/70-modules/quicksearch/_module.scss */
.quicksearch__channel-button.is-selected {
	color: rgb(var(--color-global-secondary));
	background: rgb(var(--color-global-positive));
}

/* line 789, scss/70-modules/quicksearch/_module.scss */
body:not(.is-touch-device) .quicksearch__channel-button.is-selected:hover {
	color: rgb(var(--color-global-secondary));
}

/* Custom font not found */
@font-face {
	font-family: "icons";
	src: url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.woff2?afa155ab01425b5578e92934061734f4") format("woff2"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.woff?afa155ab01425b5578e92934061734f4") format("woff"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.ttf?afa155ab01425b5578e92934061734f4") format("truetype"), url("<%=staticserver%>/<%=versionpath%>/_responsive/resources/80-themes/affinitydefault_hamptons_re/fonts/icons.svg?afa155ab01425b5578e92934061734f4#icons") format("svg");
	font-weight: normal;
	font-style: normal;
}

/* line 18, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon, .m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__close-button {
	font-family: "icons";
	display: inline-block;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* line 38, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-404:before {
	content: "\f101";
}

/* line 41, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-410:before {
	content: "\f102";
}

/* line 44, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-acres:before {
	content: "\f103";
}

/* line 47, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-add:before {
	content: "\f104";
}

/* line 50, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-agent-wfp:before {
	content: "\f105";
}

/* line 53, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-agent:before {
	content: "\f106";
}

/* line 56, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-alert:before {
	content: "\f107";
}

/* line 59, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-appleid:before {
	content: "\f108";
}

/* line 62, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-area:before {
	content: "\f109";
}

/* line 65, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-full:before {
	content: "\f10a";
}

/* line 68, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-slim:before {
	content: "\f10b";
}

/* line 71, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down-triangle:before {
	content: "\f10c";
}

/* line 74, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-down:before {
	content: "\f10d";
}

/* line 77, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-custom:before {
	content: "\f10e";
}

/* line 80, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-full:before {
	content: "\f10f";
}

/* line 83, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-slim:before {
	content: "\f110";
}

/* line 86, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-small:before {
	content: "\f111";
}

/* line 89, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left-thin:before {
	content: "\f112";
}

/* line 92, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-left:before {
	content: "\f113";
}

/* line 95, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-custom:before {
	content: "\f114";
}

/* line 98, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-full:before {
	content: "\f115";
}

/* line 101, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-slim:before {
	content: "\f116";
}

/* line 104, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-small:before {
	content: "\f117";
}

/* line 107, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right-thin:before {
	content: "\f118";
}

/* line 110, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-right:before {
	content: "\f119";
}

/* line 113, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up-double-full:before {
	content: "\f11a";
}

/* line 116, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up-full:before {
	content: "\f11b";
}

/* line 119, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrow-up:before {
	content: "\f11c";
}

/* line 122, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-arrows:before {
	content: "\f11d";
}

/* line 125, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-attention-triangle:before {
	content: "\f11e";
}

/* line 128, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-attention:before {
	content: "\f11f";
}

/* line 131, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-back:before {
	content: "\f120";
}

/* line 134, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bath-custom:before {
	content: "\f121";
}

/* line 137, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bath:before {
	content: "\f122";
}

/* line 140, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bed-custom:before {
	content: "\f123";
}

/* line 143, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bed:before {
	content: "\f124";
}

/* line 146, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-bike:before {
	content: "\f125";
}

/* line 149, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-blog:before {
	content: "\f126";
}

/* line 152, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-boy:before {
	content: "\f127";
}

/* line 155, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-building:before {
	content: "\f128";
}

/* line 158, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-buildings:before {
	content: "\f129";
}

/* line 161, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calculator:before {
	content: "\f12a";
}

/* line 164, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calendar-2:before {
	content: "\f12b";
}

/* line 167, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-calendar:before {
	content: "\f12c";
}

/* line 170, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-car:before {
	content: "\f12d";
}

/* line 173, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-carret-down:before {
	content: "\f12e";
}

/* line 176, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-change-direction-reversed:before {
	content: "\f12f";
}

/* line 179, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-change-direction:before {
	content: "\f130";
}

/* line 182, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-checked-fill:before {
	content: "\f131";
}

/* line 185, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-checked:before {
	content: "\f132";
}

/* line 188, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-clock-slim:before {
	content: "\f133";
}

/* line 191, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-close-slim:before {
	content: "\f134";
}

/* line 194, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-close:before {
	content: "\f135";
}

/* line 197, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-cloud-search:before {
	content: "\f136";
}

/* line 200, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-collapsed:before {
	content: "\f137";
}

/* line 203, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-comment:before {
	content: "\f138";
}

/* line 206, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-comments:before {
	content: "\f139";
}

/* line 209, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-compare:before {
	content: "\f13a";
}

/* line 212, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-delete:before {
	content: "\f13b";
}

/* line 215, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-directions:before {
	content: "\f13c";
}

/* line 218, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-down:before {
	content: "\f13d";
}

/* line 221, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-draw:before {
	content: "\f13e";
}

/* line 224, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-earth:before {
	content: "\f13f";
}

/* line 227, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-edit:before {
	content: "\f140";
}

/* line 230, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-energy-certificates:before {
	content: "\f141";
}

/* line 233, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-expand-down:before {
	content: "\f142";
}

/* line 236, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-expand:before {
	content: "\f143";
}

/* line 239, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-extend:before {
	content: "\f144";
}

/* line 242, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-external-link-alt:before {
	content: "\f145";
}

/* line 245, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-external-link:before {
	content: "\f146";
}

/* line 248, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-eye-off:before {
	content: "\f147";
}

/* line 251, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-eye:before {
	content: "\f148";
}

/* line 254, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-facebook-2:before {
	content: "\f149";
}

/* line 257, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-facebook:before {
	content: "\f14a";
}

/* line 260, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-favicon-wfp:before {
	content: "\f14b";
}

/* line 263, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter-more-reverse:before {
	content: "\f14c";
}

/* line 266, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter-more:before {
	content: "\f14d";
}

/* line 269, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-filter:before {
	content: "\f14e";
}

/* line 272, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flag-market:before {
	content: "\f14f";
}

/* line 275, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flag:before {
	content: "\f150";
}

/* line 278, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flickr:before {
	content: "\f151";
}

/* line 281, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-flipbook:before {
	content: "\f152";
}

/* line 284, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-floor-plan:before {
	content: "\f153";
}

/* line 287, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-full-screen:before {
	content: "\f154";
}

/* line 290, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-funnel:before {
	content: "\f155";
}

/* line 293, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gallery-view:before {
	content: "\f156";
}

/* line 296, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gallery:before {
	content: "\f157";
}

/* line 299, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gas:before {
	content: "\f158";
}

/* line 302, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-gear:before {
	content: "\f159";
}

/* line 305, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-girl:before {
	content: "\f15a";
}

/* line 308, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-globe:before {
	content: "\f15b";
}

/* line 311, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-heart-full:before {
	content: "\f15c";
}

/* line 314, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-heart:before {
	content: "\f15d";
}

/* line 317, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-small:before {
	content: "\f15e";
}

/* line 320, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-value:before {
	content: "\f15f";
}

/* line 323, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-1-full:before {
	content: "\f160";
}

/* line 326, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-2-full:before {
	content: "\f161";
}

/* line 329, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-frame:before {
	content: "\f162";
}

/* line 332, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-small:before {
	content: "\f163";
}

/* line 335, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-value:before {
	content: "\f164";
}

/* line 338, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house-wfp:before {
	content: "\f165";
}

/* line 341, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-house:before {
	content: "\f166";
}

/* line 344, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-info:before {
	content: "\f167";
}

/* line 347, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-instagram:before {
	content: "\f168";
}

/* line 350, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-link:before {
	content: "\f169";
}

/* line 353, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-linkedin:before {
	content: "\f16a";
}

/* line 356, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-list-view-full:before {
	content: "\f16b";
}

/* line 359, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-list-view:before {
	content: "\f16c";
}

/* line 362, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-location-target:before {
	content: "\f16d";
}

/* line 365, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-location:before {
	content: "\f16e";
}

/* line 368, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-log-in-slim:before {
	content: "\f16f";
}

/* line 371, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-log-in:before {
	content: "\f170";
}

/* line 374, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-line:before {
	content: "\f171";
}

/* line 377, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-new:before {
	content: "\f172";
}

/* line 380, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail-slim:before {
	content: "\f173";
}

/* line 383, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-mail:before {
	content: "\f174";
}

/* line 386, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-slim:before {
	content: "\f175";
}

/* line 389, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-view-full:before {
	content: "\f176";
}

/* line 392, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-map-view:before {
	content: "\f177";
}

/* line 395, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-market-data:before {
	content: "\f178";
}

/* line 398, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-matterPort3dVR:before {
	content: "\f179";
}

/* line 401, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-median:before {
	content: "\f17a";
}

/* line 404, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-menu-spaced:before {
	content: "\f17b";
}

/* line 407, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-menu:before {
	content: "\f17c";
}

/* line 410, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-minus:before {
	content: "\f17d";
}

/* line 413, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-multiple-listings:before {
	content: "\f17e";
}

/* line 416, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-openhouse:before {
	content: "\f17f";
}

/* line 419, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pan:before {
	content: "\f180";
}

/* line 422, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pause-thin:before {
	content: "\f181";
}

/* line 425, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pause:before {
	content: "\f182";
}

/* line 428, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pdf:before {
	content: "\f183";
}

/* line 431, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person-2:before {
	content: "\f184";
}

/* line 434, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person-sign-up:before {
	content: "\f185";
}

/* line 437, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-person:before {
	content: "\f186";
}

/* line 440, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-2:before {
	content: "\f187";
}

/* line 443, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-classic-full:before {
	content: "\f188";
}

/* line 446, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-classic:before {
	content: "\f189";
}

/* line 449, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone-slim:before {
	content: "\f18a";
}

/* line 452, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-phone:before {
	content: "\f18b";
}

/* line 455, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-photo:before {
	content: "\f18c";
}

/* line 458, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pin:before {
	content: "\f18d";
}

/* line 461, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pinterest-2:before {
	content: "\f18e";
}

/* line 464, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-pinterest:before {
	content: "\f18f";
}

/* line 467, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-empty:before {
	content: "\f190";
}

/* line 470, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-round:before {
	content: "\f191";
}

/* line 473, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-play-square:before {
	content: "\f192";
}

/* line 476, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-plus:before {
	content: "\f193";
}

/* line 479, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-portfolio:before {
	content: "\f194";
}

/* line 482, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-printer:before {
	content: "\f195";
}

/* line 485, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-quote:before {
	content: "\f196";
}

/* line 488, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-radius:before {
	content: "\f197";
}

/* line 491, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-refine:before {
	content: "\f198";
}

/* line 494, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-remove:before {
	content: "\f199";
}

/* line 497, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-restaurant:before {
	content: "\f19a";
}

/* line 500, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-return:before {
	content: "\f19b";
}

/* line 503, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-ribbon:before {
	content: "\f19c";
}

/* line 506, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-rotate:before {
	content: "\f19d";
}

/* line 509, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-rss:before {
	content: "\f19e";
}

/* line 512, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-school:before {
	content: "\f19f";
}

/* line 515, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-schools:before {
	content: "\f1a0";
}

/* line 518, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-search:before {
	content: "\f1a1";
}

/* line 521, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-share:before {
	content: "\f1a2";
}

/* line 524, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-sign-up-slim:before {
	content: "\f1a3";
}

/* line 527, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-sign-up:before {
	content: "\f1a4";
}

/* line 530, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-signin:before {
	content: "\f1a5";
}

/* line 533, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-split-view:before {
	content: "\f1a6";
}

/* line 536, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star-empty:before {
	content: "\f1a7";
}

/* line 539, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star-half:before {
	content: "\f1a8";
}

/* line 542, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-star:before {
	content: "\f1a9";
}

/* line 545, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-stop:before {
	content: "\f1aa";
}

/* line 548, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-street-view:before {
	content: "\f1ab";
}

/* line 551, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-switch:before {
	content: "\f1ac";
}

/* line 554, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-tag:before {
	content: "\f1ad";
}

/* line 557, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-toggle-fullscreen:before {
	content: "\f1ae";
}

/* line 560, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-transit:before {
	content: "\f1af";
}

/* line 563, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-trash:before {
	content: "\f1b0";
}

/* line 566, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-tumblr:before {
	content: "\f1b1";
}

/* line 569, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-twitter:before {
	content: "\f1b2";
}

/* line 572, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-units:before {
	content: "\f1b3";
}

/* line 575, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-up:before {
	content: "\f1b4";
}

/* line 578, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-upload:before {
	content: "\f1b5";
}

/* line 581, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-vcard:before {
	content: "\f1b6";
}

/* line 584, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-video:before {
	content: "\f1b7";
}

/* line 587, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-vimeo:before {
	content: "\f1b8";
}

/* line 590, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-virtual-tour:before {
	content: "\f1b9";
}

/* line 593, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-volume-mute:before {
	content: "\f1ba";
}

/* line 596, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-volume:before {
	content: "\f1bb";
}

/* line 599, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-walk:before {
	content: "\f1bc";
}

/* line 602, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wechat:before {
	content: "\f1bd";
}

/* line 605, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wfp:before {
	content: "\f1be";
}

/* line 608, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-whatsapp:before {
	content: "\f1bf";
}

/* line 611, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-wordpress:before {
	content: "\f1c0";
}

/* line 614, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-world:before {
	content: "\f1c1";
}

/* line 617, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-yelp:before {
	content: "\f1c2";
}

/* line 620, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-youtube-2:before {
	content: "\f1c3";
}

/* line 623, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-youtube:before {
	content: "\f1c4";
}

/* line 626, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomin-slim:before {
	content: "\f1c5";
}

/* line 629, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomin:before {
	content: "\f1c6";
}

/* line 632, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomout-slim:before {
	content: "\f1c7";
}

/* line 635, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomout:before {
	content: "\f1c8";
}

/* line 638, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.icon-zoomreset:before {
	content: "\f1c9";
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icon[class*=arrow-left], .is-rtl .m-quicksearch--cityhomes.m-quicksearch--above-all [class*=arrow-left].search-suggest__close-button, .m-quicksearch--cityhomes.m-quicksearch--above-all .is-rtl [class*=arrow-left].search-suggest__close-button, .is-rtl
.icon-custom[class*=arrow-left] {
	transform: rotate(180deg);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .icon[class*=arrow-right], .is-rtl .m-quicksearch--cityhomes.m-quicksearch--above-all [class*=arrow-right].search-suggest__close-button, .m-quicksearch--cityhomes.m-quicksearch--above-all .is-rtl [class*=arrow-right].search-suggest__close-button, .is-rtl
.icon-custom[class*=arrow-right] {
	transform: rotate(-180deg);
}

/* line 13, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.c-modal.quicksearch-modal {
	background-color: rgba(var(--c-modal-overlay-background), 0);
}

/* line 19, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all {
	background-color: rgb(var(--color-global-secondary));
	padding: 20px;
	width: auto;
}

@media only screen and (min-width: 60em) {
	/* line 19, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
	.m-quicksearch--cityhomes.m-quicksearch--above-all {
		min-width: 420px;
		right: -1000px;
	}
}

/* line 30, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .quicksearch__tooltip-wrapper .quicksearch__tooltip {
	text-align: left;
	color: rgb(var(--color-global-positive));
	top: 40%;
	transform: none;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-quicksearch--cityhomes.m-quicksearch--above-all .quicksearch__tooltip-wrapper .quicksearch__tooltip {
	text-align: right;
}

/* line 37, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .quicksearch__tooltip-wrapper .quicksearch__tooltip-title {
	color: rgb(var(--color-global-positive));
}

/* line 42, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .quicksearch__items {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

/* line 49, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .quicksearch__item {
	border-radius: 3px;
	background-color: rgb(var(--color-global-positive));
}

/* line 54, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .quicksearch__wrapper {
	border: 0;
	margin-bottom: 20px;
}

/* line 59, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .quicksearch__form-container {
	flex: 1;
}

/* line 64, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__item {
	flex: 1;
	border-left: 0;
	padding: 0;
}

/* line 69, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__item .selectboxit-container {
	padding: 0;
}

/* line 73, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__item .selectboxit-container-hover {
	background-color: rgb(var(--color-global-widget2));
}

/* line 77, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__item .selectboxit-btn.selectboxit-enabled {
	border-radius: 5px 5px 0 0;
	background-color: rgb(var(--color-global-positive));
}

/* line 82, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__item .selectboxit-btn.selectboxit-enabled:hover {
	background-color: rgb(var(--color-global-widget2));
	color: rgb(var(--color-global-secondary));
}

/* line 89, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__wrapper .c-target-locations-icon {
	color: rgb(var(--color-dynamic-primary-color));
}

/* line 94, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__close-button {
	color: rgb(var(--color-global-positive));
	padding: 0;
}

/* line 654, resources/80-themes/AffinityDefault_Hamptons_RE/fonts/_icons.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__close-button:before {
	content: "\f135";
}

/* line 101, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__item:last-of-type {
	padding-left: 15px;
	flex: 0;
	align-items: center;
	justify-content: center;
	font-size: 1.4em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__item:last-of-type {
	padding-left: initial;
	padding-right: 15px;
}

/* line 109, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .search-suggest__item:last-of-type .search-suggest__close-text {
	display: none;
}

/* line 115, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .c-search-suggest {
	background-color: transparent;
	max-height: 90vh;
}

/* line 119, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .c-search-suggest .group-data {
	background-color: rgb(var(--color-global-positive));
}

/* line 122, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .c-search-suggest .group-data .suggest-container__links {
	background-color: rgb(var(--color-global-positive));
}

/* line 127, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .c-search-suggest .search-suggest__no-results {
	color: rgb(var(--color-global-positive));
}

/* line 132, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes.m-quicksearch--above-all .quicksearch__tooltip-title {
	text-transform: uppercase;
}

/* line 137, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes:not(.m-quicksearch--above-all) {
	vertical-align: middle;
}

/* line 140, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes:not(.m-quicksearch--above-all) .quicksearch__icon {
	position: relative;
	left: auto;
	transform: none;
	width: 30px;
	height: 30px;
	text-align: center;
	font-size: 1.4em;
	line-height: 32px;
	color: rgb(var(--color-dynamic-header-text));
}

/* line 158, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes:not(.m-quicksearch--above-all) .quicksearch__wrapper {
	background-color: transparent;
}

/* line 162, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes:not(.m-quicksearch--above-all) .search-suggest__wrapper {
	cursor: pointer;
	background-color: transparent;
	border-radius: 4px;
}

/* line 167, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes:not(.m-quicksearch--above-all) .search-suggest__wrapper:hover {
	background-color: rgb(var(--color-dynamic-background-accent));
	color: rgb(var(--color-global-secondary));
}

/* line 173, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch--cityhomes:not(.m-quicksearch--above-all) .quicksearch__item {
	display: none;
}

@media only screen and (max-width: 59.999em) {
	/* line 178, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
	.m-quicksearch--cityhomes:not(.m-quicksearch--above-all) .quicksearch__icon,
	.m-quicksearch--cityhomes:not(.m-quicksearch--above-all) .search-suggest__wrapper {
		background-color: transparent;
	}
}

/* line 185, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.m-quicksearch .search-suggest__detail--address {
	max-width: 35%;
}

@media only screen and (max-width: 59.999em) {
	/* line 191, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
	.m-quicksearch {
		width: auto;
		display: inline-block;
	}
	/* line 196, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
	.search-suggest__wrapper .quicksearch__icon {
		padding: 0;
	}
	/* line 200, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
	.quicksearch__item {
		padding-left: 40px;
	}
	/* line 203, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
	.m-quicksearch:not(.m-quicksearch--cityhomes) .quicksearch__item {
		padding-top: 10px;
	}
}

/* line 210, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.paw-main-header .m-quicksearch {
	width: auto;
	display: inline-block;
}

/* line 214, scss/80-themes/AffinityDefault/70-modules/quicksearch/module.scss */
.paw-main-header .m-quicksearch--cityhomes:not(.m-quicksearch--above-all) .search-suggest__wrapper:hover .quicksearch__icon {
	color: rgb(var(--color-global-secondary));
}

/*# sourceMappingURL=../../../../../true */